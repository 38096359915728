import {
  ArItem,
  BundleOffer,
  ECourtesy,
  EPaxType,
  ETripType,
  Fare,
  FareDetail,
  IBEPassengerDetail,
  IBEPax,
  Journey,
  MMBPax,
  SearchingDetail,
  ServiceCharge,
  TripDetail,
} from "@hkexpressairwayslimited/ui";
import { differenceInDays, differenceInSeconds, differenceInYears } from "date-fns";
import { envConfig } from "env";
import { formateDateFormat2 } from "lib/features/flight-book/flight-select/helper";
import { ManageMyBookingTripDetail } from "lib/features/manage-my-booking/my-trips/definition";
import { checkTripType } from "lib/features/manage-my-booking/my-trips/payment/helper";
import { cloneDeep, groupBy, omit } from "lodash";
import { I18nInstance } from "modules/common/common/utils/i18n-utils";
import { SelectedArItems } from "store/sessionStorage/slices/onlineCheckInSlice";
import { EArItemType, EBaggageArCode } from "ui/features/flight-booking/add-extras";
import { md5 } from "./md5";
export type IbeTripDetailType = TripDetail & { passengers: IBEPax[] };

export type NewSelectItemType = {
  type: string;
  code: string;
  price: number;
  segmentOrigin: string;
  segmentDestination: string;
  byPax: { paxId: string; amount: number }[];
  paxId?: string;
  amount?: number;
};

export interface AnalyticPaymentDetail {
  confirmation_code: string;
  currency: string;
  total_fare_amount?: number;
  total_amount?: number;
  payment_method: string;
  asiamiles_point: number;
  voucher_code: string;
  voucher_amount: number;
  voucher_instance: number;
}
export interface AnalyticIBEDetail {
  event?: string;
  page?: {
    name?: string;
    language?: string;
    platform?: string;
    [key: string]: any;
  };
  flight_booking?:
    | {
        booking_type?: string;
        trip_type?: string;
        departure_date?: string;
        return_date?: string;
        origin?: string;
        destination?: string;
        booking_window?: number | string;
        trip_duration?: number | string;
        adult?: number | string;
        child?: number | string;
        infant?: number | string;
        insurance_amount?: number | string;
        insurance_instance?: number | string;
        sim_amount?: number | string;
        sim_instance?: number | string;
        [key: string]: any;
      }
    | string;
  productListItems?: any[] | string;
  payment?: AnalyticPaymentDetail | string;
  interaction?: {
    name: string;
  };
  cart_add?: {
    insurance_amount: number;
    insurance_instance: number;
    sim_amount: number;
    sim_instance: number;
  };
  member?: {
    id: string;
    login_type: string;
  };
  marketing?: {
    tracking_code: string;
  };
}
interface FlatSelectItem {
  type: string;
  code: string;
  paxId: string;
  amount: number;
  price: number;
  origin?: string;
  destination?: string;
}

export function getAnalyticFareType(bundle: BundleOffer[] | BundleOffer) {
  const bundleOffer = Array.isArray(bundle) ? bundle[0] : bundle || {};
  return bundleOffer.type === "ESSE"
    ? "Essential"
    : bundleOffer.type === "LITE"
      ? "Lite"
      : bundleOffer.type === "MAX"
        ? "Max"
        : bundleOffer.type === "U_LITE"
          ? "UltraLite"
          : bundleOffer.type === "FUN"
            ? "Fun"
            : bundleOffer.type === "FUNFLEX"
              ? "FunFlex"
              : "";
}

export const productListItemFare = (journey: Journey) => {
  const fareType = getAnalyticFareType(journey?.fare?.bundle_offers);
  return {
    priceTotal: 0,
    quantity: 0,
    productCategories: [{ categoryID: fareType }],
  };
};

export const cartAddItem = {
  insurance_amount: 0,
  insurance_instance: 0,
  sim_amount: 0,
  sim_instance: 0,
};

export const adobeDataLayerPush = (data: AnalyticIBEDetail, c?: number) => {
  const w: any = window;
  const count = c || 0;
  if (w?.adobeDataLayer === undefined) {
    if (count > 3) {
      return;
    }
    setTimeout(() => {
      adobeDataLayerPush(data, count + 1);
    }, 800);
  } else {
    data && w.adobeDataLayer?.push(data);
    console.log(data, "dataLayer");
  }
};

export const pushAdobeDataLayer = (data: AnalyticIBEDetail) => {
  adobeDataLayerPush(data);
};

export const getPassengerGender = (courtesy: string) => {
  return courtesy === ECourtesy.Master || courtesy === ECourtesy.Mr ? "male" : "female";
};

export const flatSelected = (selectItem: ArItem, available: ArItem[], origin: string, destination: string) => {
  const byPax = selectItem.byPax || [];
  const curItem = available.find((item) => item.code === selectItem.code && item.type === selectItem.type);
  const selectedArr: FlatSelectItem[] = [];
  byPax.forEach((item) => {
    if (item.amount > 0) {
      selectedArr.push({
        type: selectItem.type,
        code: selectItem.code,
        paxId: item.paxId,
        amount: item.amount,
        price:
          selectItem.type === EArItemType.SEAT
            ? Number(selectItem.price)
            : curItem && Number(curItem.price)
              ? Number(curItem.price)
              : 0,
        origin,
        destination,
      });
    }
  });
  return selectedArr;
};

export const getAllSelectedItem = (tripDetail: TripDetail | ManageMyBookingTripDetail) => {
  const journeys = tripDetail?.journeys || [];
  let selectedArr: FlatSelectItem[] = [];
  const tripSelectedArr = tripDetail?.arItems?.selected || [];
  const tripAvailable = tripDetail?.arItems?.available || [];
  tripSelectedArr.forEach((tripSelected) => {
    const tripFlatSelectedArr = flatSelected(tripSelected, tripAvailable, "", "");
    selectedArr = [...selectedArr, ...tripFlatSelectedArr];
  });
  journeys.forEach((journey) => {
    const journeySelectedArr = journey?.arItems?.selected || [];
    const journeyAvailable = journey?.arItems?.available || [];
    journeySelectedArr.forEach((journeySelected) => {
      const journeyFlatSelectedArr = flatSelected(
        journeySelected,
        journeyAvailable,
        journey?.origin,
        journey?.destination
      );
      selectedArr = [...selectedArr, ...journeyFlatSelectedArr];
    });
    const segments = journey?.segments || [];
    segments.forEach((segment) => {
      const segmentSelectedArr = segment?.arItems?.selected || [];
      const segmentAvailable = segment?.arItems?.available || [];
      segmentSelectedArr.forEach((segmentSelected) => {
        const segmentFlatSelectedArr = flatSelected(
          segmentSelected,
          segmentAvailable,
          segment?.origin,
          segment?.destination
        );
        selectedArr = [...selectedArr, ...segmentFlatSelectedArr];
      });
    });
  });
  const filterSelectedArr = selectedArr.filter((item) => item.code !== EBaggageArCode._0KG);
  return filterSelectedArr;
};

export const getSelectedItemValue = (
  selectArr: FlatSelectItem[],
  type: string,
  origin?: string,
  destination?: string
) => {
  let price = 0;
  let instance = 0;
  const set = new Set();
  const filterArr =
    type === EArItemType.INSURANCE || type === EArItemType.SIM_CARD
      ? selectArr.filter((item) => item.type === type)
      : selectArr.filter((item) => item.origin === origin && item.destination === destination && item.type === type);
  if (filterArr.length > 0) {
    filterArr.forEach((item) => {
      price = price + item.amount * item.price;
      instance = instance + item.amount;
      set.add(item.amount > 0 ? Array(item.amount).fill(item.code).join(",") : item.code);
    });
    const arr = [...set];
    return {
      price,
      instance,
      code: arr.join(","),
    };
  } else {
    return null;
  }
};

export const getFareDetailAmount = (journey: Journey, paxType: string) => {
  const fare = journey.fare;
  const fareDetail = fare[`${paxType}_fare_details` as keyof Fare];
  const fareAmount =
    fareDetail &&
    (fareDetail as FareDetail)?.service_charge?.find((charge: ServiceCharge) => charge.type === "FARE")?.amount;
  return fareAmount || 0;
};

export function getBookingWindow(start: string) {
  const startDate = new Date(start);
  const endDate = new Date();
  const days = differenceInDays(startDate, endDate);
  const seconds = differenceInSeconds(startDate, endDate);
  return days > 0 ? days : seconds > 0 ? 1 : 0;
}

export const getAnalyticIbeFlightBooking = (searchDetail: any, trip?: any) => {
  const searchingDetail = { ...(searchDetail || {}) };
  const tripType = searchingDetail?.tripType;
  if (tripType !== ETripType.oneWay && trip && searchingDetail?.trips && searchingDetail.trips?.length === 1) {
    if (searchingDetail.trips[0]?.index === 0) {
      searchingDetail.trips = [
        searchingDetail.trips[0],
        {
          from: trip?.journeys?.[1]?.origin ?? "",
          to: trip?.journeys?.[1]?.destination ?? "",
          date: formateDateFormat2(trip?.journeys?.[1]?.date_time) ?? "",
        },
      ];
    } else if (searchingDetail.trips[0]?.index === 1) {
      searchingDetail.trips = [
        {
          from: trip?.journeys?.[0]?.origin ?? "",
          to: trip?.journeys?.[0]?.destination ?? "",
          date: formateDateFormat2(trip?.journeys?.[0]?.date_time) ?? "",
        },
        searchingDetail.trips[0],
      ];
    }
  }
  const trips0Date = searchingDetail?.trips?.[0]?.date;
  const trips0DateFormat = trips0Date ? formateDateFormat2(trips0Date) : "";
  const trips1Date = searchingDetail?.trips?.[1]?.date;
  const trips1DateFormat = trips1Date ? formateDateFormat2(trips1Date) : "";
  const origin0 = searchingDetail?.trips?.[0]?.from || "";
  const destination0 = searchingDetail?.trips?.[0]?.to || "";
  const origin1 = searchingDetail?.trips?.[1]?.from || "";
  const destination1 = searchingDetail?.trips?.[1]?.to || "";
  const todayFormat = formateDateFormat2(new Date().toString());
  const passenger_count = searchingDetail?.passenger_count;
  const flight_booking = {
    booking_type: "B2C",
    trip_type:
      tripType === ETripType.roundTrip
        ? "round trip"
        : tripType === ETripType.oneWay
          ? "one way"
          : tripType === ETripType.multiCity
            ? "multi city"
            : "",
    departure_date: trips0DateFormat,
    return_date: trips1DateFormat,
    origin: origin0,
    destination: tripType === ETripType.multiCity ? destination1 : destination0,
    booking_window: trips0DateFormat ? getBookingWindow(trips0DateFormat) : "",
    trip_duration: trips0DateFormat && trips1DateFormat ? differenceInDays(trips1DateFormat, trips0DateFormat) : "",
    adult: passenger_count?.Adult,
    child: passenger_count?.Child,
    infant: passenger_count?.Infant,
    total_passenger: passenger_count ? passenger_count.Adult + passenger_count.Child : "",
  };
  return flight_booking;
};

export const getAnalyticIbeChangeFlightBooking = (searchingDetail: SearchingDetail, tripDetail: TripDetail) => {
  const originFlightBooking = getAnalyticIbeFlightBooking(searchingDetail);
  const trips0Date = tripDetail?.journeys?.[0]?.date;
  const trips0DateFormat = trips0Date ? formateDateFormat2(trips0Date) : "";
  const trips1Date = tripDetail?.journeys?.[1]?.date;
  const trips1DateFormat = trips1Date ? formateDateFormat2(trips1Date) : "";
  const todayFormat = formateDateFormat2(new Date().toString());
  const flight_booking = {
    ...originFlightBooking,
    departure_date: trips0DateFormat,
    return_date: trips1DateFormat,
    booking_window: trips0DateFormat ? getBookingWindow(trips0DateFormat) : "",
    trip_duration: trips1DateFormat && trips0DateFormat ? differenceInDays(trips1DateFormat, trips0DateFormat) : "",
  };
  return flight_booking;
};

export const getAnalyticFlightBooking = (curTripDetail: any, trip?: any) => {
  const tripDetail = { ...(curTripDetail || {}) };
  const tripType = checkTripType(trip || tripDetail);
  if (tripType !== ETripType.oneWay && trip && tripDetail?.journeys && tripDetail.journeys?.length === 1) {
    const journeyIndex = tripDetail?.journeys?.[0]?.index;
    if (journeyIndex === 0) {
      tripDetail.journeys = [tripDetail.journeys[0], trip.journeys?.[1]];
    } else if (journeyIndex === 1) {
      tripDetail.journeys = [trip.journeys?.[0], tripDetail.journeys[0]];
    }
  }
  const trips0Date = tripDetail?.journeys?.[0]?.date_time || tripDetail?.journeys?.[0]?.departure_date_time;
  const trips0DateFormat = trips0Date ? formateDateFormat2(trips0Date) : "";
  const trips1Date = tripDetail?.journeys?.[1]?.date_time || tripDetail?.journeys?.[1]?.departure_date_time;
  const trips1DateFormat = trips1Date ? formateDateFormat2(trips1Date) : "";
  const todayFormat = formateDateFormat2(new Date().toString());
  const adultCount =
    tripDetail?.passengers?.filter((item: IBEPax | MMBPax) => item?.paxType === EPaxType.Adult)?.length || 0;
  const childCount =
    tripDetail?.passengers?.filter((item: IBEPax | MMBPax) => item?.paxType === EPaxType.Child)?.length || 0;
  const infantCount =
    tripDetail?.passengers?.filter((item: IBEPax | MMBPax) => item?.paxType === EPaxType.Infant)?.length || 0;
  const flight_booking = {
    booking_type: "B2C",
    trip_type:
      tripType === ETripType.roundTrip
        ? "round trip"
        : tripType === ETripType.oneWay
          ? "one way"
          : tripType === ETripType.multiCity
            ? "multi city"
            : "",
    departure_date: trips0DateFormat,
    return_date: trips1DateFormat,
    origin: tripDetail?.journeys?.[0]?.origin,
    destination:
      tripType === ETripType.multiCity
        ? tripDetail?.journeys?.[1]?.destination
        : tripDetail?.journeys?.[0]?.destination,
    booking_window: trips0DateFormat ? getBookingWindow(trips0DateFormat) : "",
    trip_duration: trips0DateFormat && trips1DateFormat ? differenceInDays(trips1DateFormat, trips0DateFormat) : "",
    adult: adultCount,
    child: childCount,
    infant: infantCount,
    total_passenger: adultCount + childCount,
    hashed_pnr: tripDetail?.sales_reference ? md5(tripDetail?.sales_reference) : "",
  };
  return flight_booking;
};

export const getAnalyticPage = (newPage: { name: string }) => {
  const languageLocale = I18nInstance.getI18nInstance()?.language || envConfig.defaultLanguage;
  const languageLocaleArr = languageLocale.split("-");
  const language = languageLocaleArr ? languageLocaleArr[0] : "en";
  return {
    language,
    platform: "web",
    ...newPage,
  };
};

export const getExtrasProductListItems = (passengers: (IBEPax | MMBPax)[], journeys: Journey[]) => {
  const productListItems: any[] = [];
  passengers.forEach((passenger) => {
    let cur = 0;
    if (journeys?.length && journeys?.length > 0) {
      journeys?.map((journey, i) => {
        journey.segments?.map((segment, j) => {
          const { origin, destination, flight_number } = segment;
          productListItems.push({
            ...productListItemFare(journey),
            SKU: `${origin}-${destination}`,
            _experience: {
              analytics: {
                customDimensions: {
                  eVars: {
                    eVar3: ++cur,
                    eVar6: flight_number,
                    eVar33: "",
                    eVar34: "",
                    eVar35: "",
                    eVar36: differenceInYears(new Date(), new Date(passenger?.DOB)),
                    eVar37: getPassengerGender(passenger?.courtesy as string),
                    eVar38: "",
                    eVar39: "",
                    eVar40: "",
                  },
                },
                event1to100: {
                  event1: "",
                  event2: "",
                  event3: "",
                  event4: "",
                  event5: "",
                  event6: "",
                  event7: "",
                  event8: "",
                  event9: "",
                  event10: "",
                  event70: "",
                  event71: "",
                  event72: "",
                  event73: "",
                  event74: "",
                  event75: "",
                  event76: "",
                  event77: "",
                  event78: "",
                  event79: "",
                },
              },
            },
          });
        });
      });
    }
  });
  return productListItems;
};

export const getIbeExtrasProductListItems = (passengerDetail: IBEPassengerDetail, tripDetail: TripDetail) => {
  const passengers = passengerDetail?.passengers?.filter((passenger) => passenger.paxType !== EPaxType.Infant) || [];
  const journeys = tripDetail?.journeys;
  return getExtrasProductListItems(passengers, journeys);
};

export const getCommonExtrasProductListItems = (curTripDetail: any, trip?: any) => {
  const tripDetail = { ...(curTripDetail || {}) };
  const passengers = tripDetail?.passengers?.filter((passenger: any) => passenger.paxType !== EPaxType.Infant) || [];
  const tripType = checkTripType(trip || tripDetail);
  if (tripType !== ETripType.oneWay && trip && tripDetail?.journeys && tripDetail.journeys?.length === 1) {
    const journeyIndex = tripDetail?.journeys?.[0]?.index;
    if (journeyIndex === 0) {
      tripDetail.journeys = [tripDetail.journeys[0], trip.journeys?.[1]];
    } else if (journeyIndex === 1) {
      tripDetail.journeys = [trip.journeys?.[0], tripDetail.journeys[0]];
    }
  }
  const journeys = tripDetail?.journeys || [];
  return getExtrasProductListItems(passengers, journeys);
};

export const getNewSelectItems = (
  selectedItems: SelectedArItems[],
  tripDetail: ManageMyBookingTripDetail,
  arItemType?: EArItemType
) => {
  const result: NewSelectItemType[] = [];
  const getSegment = (segmentKey: string) => {
    let segment = undefined;
    tripDetail?.journeys.forEach((journey) => {
      const cur = journey.segments.find((segment) => segment.segment_key === segmentKey);
      if (cur) {
        segment = cur;
      }
    });
    return segment;
  };
  selectedItems?.forEach((selectedItem) => {
    selectedItem?.arItems?.forEach((item) => {
      const curSegmentKey = selectedItem.segmentKey;
      const curJourney = tripDetail?.journeys?.find((journey) => journey.journey_key === selectedItem.journeyKey);
      const firstFlightIndex =
        arItemType === EArItemType.BAGGAGE ? curJourney?.segments?.findIndex((segment) => !segment.is_ferry) ?? 0 : 0;
      const curSegment = curSegmentKey
        ? getSegment(curSegmentKey)
        : arItemType === EArItemType.BAGGAGE
          ? curJourney?.segments?.[firstFlightIndex]
          : curJourney?.segments?.[0];
      const curSection = curSegmentKey ? curSegment : curJourney;
      const filterPaxArr = item.byPax?.filter((i) => i.amount !== 0) || [];
      if (filterPaxArr?.length > 0) {
        const curAvailableItem = curSection?.arItems?.available.find(
          (availableItem) => availableItem.type === item.type && availableItem.code === item.code
        );
        const newItem: NewSelectItemType = {
          type: item.type,
          code: item.code,
          price: item.price || curAvailableItem?.price || 0,
          segmentOrigin: curSegment?.origin || "",
          segmentDestination: curSegment?.destination || "",
          byPax: [],
        };
        if (arItemType === EArItemType.SEAT) {
          newItem.byPax = item.byPax || [];
        } else {
          const curSectionCurArItem = curSection?.arItems?.selected.find(
            (selectItem) => selectItem.type === item.type && selectItem.code === item.code
          );
          const curSectionCurArItemPax = curSectionCurArItem?.byPax || [];
          if (curSectionCurArItem) {
            filterPaxArr.forEach((pax) => {
              const curPax = curSectionCurArItemPax.find((p) => pax.paxId === p.paxId);
              const clonePax = cloneDeep(pax);
              if (curPax) {
                if (pax.amount > curPax.amount) {
                  clonePax.amount = pax.amount - curPax.amount;
                  newItem.byPax.push(clonePax);
                }
              } else {
                newItem.byPax.push(clonePax);
              }
            });
          } else {
            newItem.byPax = filterPaxArr;
          }
        }
        if (newItem.byPax.length > 0) {
          result.push(newItem);
        }
      }
    });
  });
  return result.reduce((a: NewSelectItemType[], i: NewSelectItemType) => {
    const byPax = i.byPax;
    byPax?.forEach((item: { paxId: string; amount: number }) => {
      a.push({
        ...i,
        paxId: item.paxId,
        amount: item.amount,
      });
    });
    return a;
  }, []);
};

export const getNewTripSelectItems = (selectedItems: SelectedArItems[], tripDetail: any) => {
  const amount =
    selectedItems?.[0]?.arItems?.[0]?.byPax?.reduce((a, i) => {
      return a + (i.amount || 0);
    }, 0) || 0;
  const curSelectedItem = tripDetail?.arItems.selected.find(
    (selectedItem: ArItem) => selectedItem.type === EArItemType.SIM_CARD
  );
  const oldAmount =
    curSelectedItem?.byPax?.reduce(
      (
        a: number,
        i: {
          paxId: string;
          amount: number;
          price?: number;
        }
      ) => {
        return a + (i.amount || 0);
      },
      0
    ) || 0;
  const curAvailableItem = tripDetail?.arItems.available.find(
    (availableItem: ArItem) => availableItem.type === EArItemType.SIM_CARD
  );
  const curAmount = amount > oldAmount ? amount - oldAmount : 0;
  return {
    amount,
    oldAmount,
    curAmount,
    instance: curAmount * (curAvailableItem?.price || 0),
  };
};

export const getAddToCartProductListItems = (
  tripDetail: ManageMyBookingTripDetail,
  arItemType: EArItemType,
  newSelectItems?: NewSelectItemType[]
) => {
  const passengers = tripDetail?.passengers?.filter((passenger) => passenger.paxType !== EPaxType.Infant) || [];
  const journeys = tripDetail?.journeys || [];
  const productListItems: any[] = [];
  passengers.forEach((passenger) => {
    let cur = 0;
    if (journeys.length > 0) {
      journeys.map((journey, i) => {
        journey.segments?.map((segment, j) => {
          const { origin, destination, flight_number } = segment;
          productListItems.push({
            ...productListItemFare(journey),
            SKU: `${origin}-${destination}`,
            passengerId: passenger.id,
            _experience: {
              analytics: {
                customDimensions: {
                  eVars: {
                    eVar3: ++cur,
                    eVar6: flight_number,
                    eVar33: "",
                    eVar34: "",
                    eVar35: "",
                    eVar36: differenceInYears(new Date(), new Date(passenger?.DOB)),
                    eVar37: getPassengerGender(passenger?.courtesy as string),
                    eVar38: "",
                    eVar39: "",
                    eVar40: "",
                  },
                },
                event1to100: {
                  event1: "",
                  event2: "",
                  event3: "",
                  event4: "",
                  event5: "",
                  event6: "",
                  event7: "",
                  event8: "",
                  event9: "",
                  event10: "",
                  event70: "",
                  event71: "",
                  event72: "",
                  event73: "",
                  event74: "",
                  event75: "",
                  event76: "",
                  event77: "",
                  event78: "",
                  event79: "",
                },
              },
            },
            priceTotal: 0,
            quantity: 0,
          });
        });
      });
    }
  });
  if (arItemType === EArItemType.SIM_CARD || arItemType === EArItemType.INSURANCE) {
    return productListItems;
  }
  newSelectItems &&
    newSelectItems.forEach((newSelectItem) => {
      const curItem = productListItems.find(
        (item) =>
          item.SKU === `${newSelectItem.segmentOrigin}-${newSelectItem.segmentDestination}` &&
          item.passengerId === newSelectItem.paxId
      );
      if (curItem) {
        if (arItemType === EArItemType.BAGGAGE) {
          const eVar38 = curItem._experience.analytics.customDimensions.eVars.eVar38;
          const event70Value = curItem._experience.analytics.event1to100.event70.value || 0;
          const event71Value = curItem._experience.analytics.event1to100.event71.value || 0;
          const price = newSelectItem?.price || 0;
          const amount = newSelectItem?.amount || 0;
          const itemCode = amount > 0 ? Array(amount).fill(newSelectItem.code).join(",") : newSelectItem.code;
          curItem._experience.analytics.customDimensions.eVars.eVar38 = eVar38 ? `${eVar38}, ${itemCode}` : itemCode;
          curItem._experience.analytics.event1to100.event70 = { value: event70Value + amount * price };
          curItem._experience.analytics.event1to100.event71 = { value: event71Value + amount };
        } else if (arItemType === EArItemType.MEAL) {
          const eVar39 = curItem._experience.analytics.customDimensions.eVars.eVar39;
          const event72Value = curItem._experience.analytics.event1to100.event72.value || 0;
          const event73Value = curItem._experience.analytics.event1to100.event73.value || 0;
          const price = newSelectItem?.price || 0;
          const amount = newSelectItem?.amount || 0;
          curItem._experience.analytics.customDimensions.eVars.eVar39 = eVar39
            ? `${eVar39}, ${newSelectItem.code}`
            : newSelectItem.code;
          curItem._experience.analytics.event1to100.event72 = { value: event72Value + amount * price };
          curItem._experience.analytics.event1to100.event73 = { value: event73Value + amount };
        } else if (arItemType === EArItemType.SEAT) {
          const price = newSelectItem?.price || 0;
          curItem._experience.analytics.customDimensions.eVars.eVar40 = newSelectItem.code;
          curItem._experience.analytics.event1to100.event74 = { value: price };
          curItem._experience.analytics.event1to100.event75 = { value: 1 };
        } else if (arItemType === EArItemType.SPORT_EQUIPMENT) {
          const price = newSelectItem?.price || 0;
          curItem._experience.analytics.event1to100.event76 = { value: price };
          curItem._experience.analytics.event1to100.event77 = { value: 1 };
        } else if (arItemType === EArItemType.U_FIRST) {
          const price = newSelectItem?.price || 0;
          curItem._experience.analytics.event1to100.event78 = { value: price };
          curItem._experience.analytics.event1to100.event79 = { value: 1 };
        }
      }
    });
  return productListItems.map((productListItem) => omit(productListItem, "passengerId"));
};

export const getAddToCartPayment = (currency: string) => {
  return {
    currency: currency || "",
    payment_method: "",
    asiamiles_point: 0,
    voucher_code: "",
    voucher_amount: 0,
    voucher_instance: 0,
    confirmation_code: "",
  };
};

export const getConfirmationProductListItems = (tripDetail: any, typeFlow?: string) => {
  const passengers = tripDetail?.passengers?.filter((passenger: any) => passenger.paxType !== EPaxType.Infant) || [];
  const journeys = tripDetail?.journeys || [];
  const productListItems: any[] = [];
  const allSelectedItem = getAllSelectedItem(tripDetail);
  const groupSelected = groupBy(allSelectedItem, (item) => item.paxId);
  const insuranceItem = getSelectedItemValue(allSelectedItem, EArItemType.INSURANCE);
  const simCardItem = getSelectedItemValue(allSelectedItem, EArItemType.SIM_CARD);
  passengers.forEach((passenger: IBEPax | MMBPax) => {
    let cur = 0;
    const curPassengerSelected = groupSelected[passenger.id] || [];
    if (journeys.length > 0) {
      journeys.map((journey: Journey, i: number) => {
        const firstFlightIndex = journey.segments.findIndex((segment) => !segment.is_ferry) ?? 0;
        journey.segments?.map((segment, j) => {
          const { origin, destination, flight_number } = segment;
          const fareAmount = getFareDetailAmount(journey, passenger?.paxType?.toLowerCase() || "");
          const baggageItem = getSelectedItemValue(
            curPassengerSelected,
            EArItemType.BAGGAGE,
            journey?.origin,
            journey?.destination
          );
          const mealItem = getSelectedItemValue(
            curPassengerSelected,
            EArItemType.MEAL,
            segment?.origin,
            segment?.destination
          );
          const seatItem = getSelectedItemValue(
            curPassengerSelected,
            EArItemType.SEAT,
            segment?.origin,
            segment?.destination
          );
          const sportItem = getSelectedItemValue(
            curPassengerSelected,
            EArItemType.SPORT_EQUIPMENT,
            journey?.origin,
            journey?.destination
          );
          const uFirstItem = getSelectedItemValue(
            curPassengerSelected,
            EArItemType.U_FIRST,
            journey?.origin,
            journey?.destination
          );
          productListItems.push({
            ...productListItemFare(journey),
            SKU: `${origin}-${destination}`,
            _experience: {
              analytics: {
                customDimensions: {
                  eVars: {
                    eVar6: flight_number,
                    eVar3: ++cur,
                    eVar36: differenceInYears(new Date(), new Date(passenger?.DOB)),
                    eVar37: getPassengerGender(passenger?.courtesy as string),
                    eVar33: baggageItem && j === firstFlightIndex ? baggageItem.code : "",
                    eVar34: mealItem ? mealItem.code : "",
                    eVar35: seatItem ? seatItem.code : "",
                    eVar38: "",
                    eVar39: "",
                    eVar40: "",
                  },
                },
                event1to100: {
                  event1: baggageItem && j === firstFlightIndex ? { value: baggageItem.price } : "",
                  event2: baggageItem && j === firstFlightIndex ? { value: baggageItem.instance } : "",
                  event3: mealItem ? { value: mealItem.price } : "",
                  event4: mealItem ? { value: mealItem.instance } : "",
                  event5: seatItem ? { value: seatItem.price } : "",
                  event6: seatItem ? { value: seatItem.instance } : "",
                  event7: sportItem && j === 0 ? { value: sportItem.price } : "",
                  event8: sportItem && j === 0 ? { value: sportItem.instance } : "",
                  event9: uFirstItem && j === 0 ? { value: uFirstItem.price } : "",
                  event10: uFirstItem && j === 0 ? { value: uFirstItem.instance } : "",
                  event70: "",
                  event71: "",
                  event72: "",
                  event73: "",
                  event74: "",
                  event75: "",
                  event76: "",
                  event77: "",
                  event78: "",
                  event79: "",
                },
              },
            },
            priceTotal: typeFlow === "IBE" ? fareAmount : 0,
            quantity: 1,
          });
        });
      });
    }
  });
  return { productListItems, insuranceItem, simCardItem };
};

export const flatPurchased = (purchasedItem: ArItem) => {
  const byPax = purchasedItem.byPax || [];
  const purchasedArr: FlatSelectItem[] = [];
  byPax.forEach((item) => {
    if (item.amount > 0) {
      purchasedArr.push({
        type: purchasedItem.type,
        code: purchasedItem.code,
        price: Number(purchasedItem.price),
        paxId: item.paxId,
        amount: item.amount,
      });
    }
  });
  return purchasedArr;
};

export const getAllPurchasedItem = (tripDetail: TripDetail) => {
  let purchasedArr: FlatSelectItem[] = [];
  const journeys = tripDetail?.journeys || [];
  const tripPurchasedArr = tripDetail?.arItems?.purchased || [];
  tripPurchasedArr.forEach((tripPurchased) => {
    const tripFlatPurchasedArr = flatPurchased(tripPurchased);
    purchasedArr = [...purchasedArr, ...tripFlatPurchasedArr];
  });
  journeys.forEach((journey) => {
    const journeyPurchasedArr = journey?.arItems?.purchased || [];
    journeyPurchasedArr.forEach((journeyPurchased) => {
      const journeyFlatPurchasedArr = flatPurchased(journeyPurchased);
      purchasedArr = [...purchasedArr, ...journeyFlatPurchasedArr];
    });
    const segments = journey?.segments || [];
    segments.forEach((segment) => {
      const segmentPurchasedArr = segment?.arItems?.purchased || [];
      segmentPurchasedArr.forEach((segmentPurchased) => {
        const segmentFlatPurchasedArr = flatPurchased(segmentPurchased);
        purchasedArr = [...purchasedArr, ...segmentFlatPurchasedArr];
      });
    });
  });
  // const filterPurchasedArr = purchasedArr.filter((item) => Number(item.price) !== 0);
  return purchasedArr;
};

export const getPurchasedOnlineCheckInItemValue = (purchasedArr: FlatSelectItem[], type: string) => {
  const filterArr = purchasedArr.filter((item) => item.type === type);
  if (filterArr.length > 0) {
    const groupItems = groupBy(filterArr, (item) => item.code);
    const arr = Object.keys(groupItems).map((key) => {
      const instance = groupItems[key]?.reduce((total, groupItem) => {
        return total + groupItem.amount;
      }, 0);
      return {
        code: key,
        instance,
      };
    });
    return arr;
  } else {
    return [];
  }
};

export const getAnalyticPath = (pathName: string = "") => {
  const languageList = envConfig.languageList;
  const path = pathName.slice(1);
  const pathArr = path.split("/") || [];
  const languageLocale = pathArr[0] || "";
  const isInclude = languageList.includes(languageLocale);
  const language = isInclude
    ? languageLocale.length === 2
      ? languageLocale
      : languageLocale.split("-")?.[0] || "en"
    : "en";
  return {
    language,
    pathName: isInclude ? pathArr.slice(1).join("/") || "/" : path,
  };
};
